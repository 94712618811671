var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "after-sale-detail" },
    [
      _c("div", { staticClass: "section-box sale-summary" }, [
        _c(
          "div",
          { staticClass: "section-content" },
          [
            _c("div", { staticClass: "sub-title" }, [_vm._v("售后概况")]),
            _c("overview-info", {
              attrs: {
                aftersaleType: _vm.aftersaleType,
                pageData: _vm.pageData
              }
            }),
            _c("process", {
              attrs: {
                pageData: _vm.pageData,
                saleSummaryTable: _vm.saleSummaryTable
              },
              on: { showReviewRecord: _vm.showReviewRecord }
            }),
            _c("cashier-info", { attrs: { pageData: _vm.pageData } }),
            _vm.pageData.aftersaleAttachList &&
            !!_vm.pageData.aftersaleAttachList.length
              ? _c("annex", {
                  attrs: {
                    aftersaleAttachList: _vm.pageData.aftersaleAttachList
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "section-line" })
      ]),
      _c("aftersale-order-vo", {
        attrs: { saleOrderTable: _vm.saleOrderTable, pageData: _vm.pageData },
        on: { goDetail: _vm.goDetail }
      }),
      _vm.showUpgradeOrTransfer
        ? _c("new-order", {
            attrs: {
              aftersaleType: _vm.aftersaleType,
              newOrder: _vm.lastTable
            },
            on: { goDetail: _vm.goNewDetail }
          })
        : _vm._e(),
      _c("refund-info", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.aftersaleType != _vm.aftersaleTypeEnum.upgrade,
            expression: "aftersaleType != aftersaleTypeEnum.upgrade"
          }
        ],
        attrs: {
          aftersaleType: _vm.aftersaleType,
          droptOutInfoTable: _vm.droptOutInfoTable,
          showXuebank: _vm.showXuebank,
          showXueName: _vm.showXueName,
          showThird: _vm.showThird
        },
        on: { showPayinfoModal: _vm.showPayinfoModal }
      }),
      _c("payments", {
        attrs: {
          aftersaleType: _vm.aftersaleType,
          pageData: _vm.pageData,
          aftersalePay: _vm.aftersalePay,
          aftersalePayStatus: _vm.aftersalePayStatus,
          sumRefundMoney: _vm.sumRefundMoney
        },
        on: { toggleAfterpayTable: _vm.toggleAfterpayTable }
      }),
      _c(
        "div",
        { staticClass: "action-btns" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "120px" },
              attrs: { size: "large" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      ),
      _c("drop-payinfo", {
        ref: "dropDrawer",
        attrs: {
          visible: _vm.payinfoModalVisible,
          columns: _vm.payinfoColumns,
          dropColumns: _vm.dropColumns,
          tableData: _vm.payinfoTable,
          extraInfo: _vm.extraInfo,
          titleList: _vm.mainGoodsInfoTable,
          moneyInfo: _vm.orderInfoTable
        },
        on: {
          "update:visible": function($event) {
            _vm.payinfoModalVisible = $event
          }
        }
      }),
      _c("HistoryAuditRecordModal", {
        ref: "historyDrawer",
        attrs: { host: "nc", visible: _vm.showHistoryAuditRecordVisible },
        on: {
          "update:visible": function($event) {
            _vm.showHistoryAuditRecordVisible = $event
          }
        }
      }),
      _c("review-history", {
        ref: "drawer",
        attrs: {
          visible: _vm.onReviewHistoryStatus,
          reviewData: _vm.reviewData
        },
        on: { "on-cancel": _vm.onReviewHistoryCancel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }